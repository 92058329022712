export const dateFormat = "YYYY年MM月DD日"
export const dateTimeFormat = "YYYY年MM月DD日, HH:mm"

/**
 *
 * Approvals status
 */
export const RELATIONPENDING = "relation-pending"
export const RELATIONAPPROVED = "relation-approved"
export const RELATIONREJECTED = "relation-rejected"

/**
 * Relationship
 */
export const RELATIVE = "親戚"
export const BESTFRIEND = "親友"
export const FRIEND = "友人"
export const ROOMMATE = "同僚"
export const SENOIR = "上司"
export const JUNIOR = "部下"
export const NEIGHBOR = "近所"
export const OTHERS = "その他"

/**
 * kind of real-state
 */
export const LAND = "土地"
export const BUILDING = "建物"
export const MANSION = "マンション"
export const APARTMENT = "アパート"
export const PARKING = "駐車場"

/**
 * asset type
 */
export const REALSTATE = "不動産"
export const SAVING = "預貯金"
export const STOCKANDBOND = "有価証券"
export const CRYPTOCURRENCY = "仮想通貨"
export const NEGATIVEASSETS = "マイナスの資産（借金・ロー）"
export const CREDITCARD = "クレジットカード"
export const ELECTRONICMONEY = "電子マネー"

/**
 * Pets kinds
 */

export const DOG = "犬"
export const CAT = "猫"
export const BIRD = "鳥"
export const FISH = "魚"

/**
 * Gender types
 */

export const MALE = "オス"
export const FEMALE = "メス"

export const YES = "ある"
export const NO = "ない"
export const YESINPAST = "過去にあった"

/*
 * storage kind type
 */
export const WILL = "遺言書"
export const PICTURES = "写真"
export const ALBUMS = "アルバム"
export const WEARS = "着物"
export const ACCESSORIES = "アクセサリー"
export const INSTRUMENTS = "楽器"
export const ARTS = "美術品"
export const ANTIQUE = "骨董品"
export const COLLECTIONS = "コレクション"
export const ALTERNMORTUARY = "仏壇・位牌"

/**
 Negative assets kinds.
 * 
 */
export const DEBT = "借金"
export const LOAN = "ローン"
export const GURANTEE_OBLIGATION = "保証債務"

/**
 * DO DONOT
 */
export const DO = "する"
export const DONOT = "しない"

/**
 * Funeral
 */

export const BUDDHISM = "仏教（お坊さんにお経をあげてもらう）"
export const SHINTO = "神道（宮司に祝詞を読んでもらう）"
export const CHRISTIANITY = "キリスト教（神父・牧師にお祈りをしてもらう）"
export const NO_RELIGION = "無宗教（宗教色のないスタイルを希望）"
export const GIVE_ME_A_GOOD_NAME = "いい戒名を付けてほしい"
export const ANY_NAME_IS_FINE = "つけてほしいが位にはこだわらない"
export const EITHER_IS_FINE = "つけてもつけなくても良い"
export const DONT_NEED = "戒名はいらない"
export const I_ALREADY_HAVE_ONE = "すでにもらっている"
export const TO_THE_JUDGMENT_OF_THE_MOURNER = "喪主の判断に任せる"
export const DECIDED_AND_HAVE_CONTRACT = "すでに決めていて契約もしている"
export const DECIDED_BUT_NO_CONTRACT = "すでに決めているが契約はしていない"
export const CONSIDERING_NOT_DECIDED = "何社か絞っているが決めていない"
export const NOT_DECIDED = "特に決めていない"
export const NO_ONE_EXCEPT_FAMILY = "家族以外誰も呼ばずに行いたい"
export const ONLY_FAMILY_AND_RELATIVES = "家族と親戚だけで送ってもらいたい"
export const ONLY_FAMILY_RELATIVES_AND_CLOSE_FRIENDS =
  "家族と親戚以外に親しい友人には見送ってほしい"
export const EVERYONE_I_MET_BY_FATE =
  "ご縁があった方に分け隔てなく声をかけてほしい"
export const SPECIFIED_LIST_ON_RELATIVES_AND_FRIEND_LIST =
  "「親戚友人リスト」で指定した人だけ呼んでほしい"
export const HAVE_SNAPSHOT_FOR_THE_DECEASED =
  "遺影用のスナップ写真を用意している"
export const WANT_YOU_TO_USE_IT_FROM_ALBUMS_AND_COMMEMORATIVE_PHOTOS =
  "アルバムや記念写真から使ってほしい"
export const IT_IS_LEFT_AS_DATA_FROM_SMARTPHONE_AND_DIGITAL_CAMERAS =
  "スマホやデジカメのデータで残している"
export const GO_HOME_IF_POSSIBLE = "できれば自宅に帰りたい"
export const KEEP_IT_IN_THE_MORTUARY_OF_THE_FUNERAL_HALL =
  "葬儀場の霊安室で預かってほしい"
export const LEAVE_IT_TO_THE_MOURNER_ENSHRINED = "こだわらないので喪主に任せる"
export const LEAVE_IT_TO_THE_MOURNER = "喪主の意向に任せる"
export const DEFINITELY_WANT = "絶対にしてほしい"
export const WANT_IF_POSSIBLE = "できればしてほしい"
export const CONSIDER_WITH_THE_SITUATION = "状況によって検討してほしい"
export const DONT_IF_POSSIBLE = "できればしないでほしい"
export const DEFINITELY_DONT_WANT = "絶対にしたくない"
export const I_DO = "する"
export const I_DONT = "しない"
export const FUNERAL_COMPANY_HALL = "葬儀社所有のホール"
export const PUBLIC_FUNERAL_HALLS = "市や町の公営斎場"
export const TEMPLE = "寺院"
export const HOME = "自宅"
export const I_HAVE_CASH = "現金を用意している"
export const INSURANCED = "保険に入っている"
export const MUTUAL_AID_FUNDS = "互助会に入っている"
export const ONE = "１枚"
export const TWO = "２枚"
export const THREE = "３枚"
/** 
 HOSPITALIZATION AND CARE HOUSE
 * 
 */
export const HOSPITALIZATION = "入院"
export const CARE = "介護"

/**
 * Notification to the municipality
 */
export const DONE = "済み"
export const YET = "未"

/**
 * Know / Dont know
 */
export const KNOW = "知っている"
export const DONTKNOW = "まだ知らない"

/**
 * Left / Not left
 */
export const LEFT = "残している"
export const NOTLEFT = "残していない"

/**
 * Decided / Not decided
 */
export const DECIDED = "残している"

export const Burial_at_home = "自宅埋葬"
export const natural_burial = "散骨・自然葬"
export const Bone_together = "ご自身の墓に一緒に納骨"
export const ossuary = "納骨堂に納める"

export const HAVELEFT = "残している"
export const HAVENOTLEFT = "残していない"

//base for gcp consultation Images
export const BaseConsultationImage = `https://storage.googleapis.com/${process?.env?.NEXT_PUBLIC_GCP_STORAGE_BUCKET_NAME}/images/consultations`

//Options for officiant introduction
export const OfficiantIntroductionOption = {
  yes: "Hope",
  no: "No hope",
  undecided: "Undecided",
}
