import { TOGGLE_SIDENAV_COLLAPSE, ADD_PAGE_NUMBER_USER } from "./Constants"
import React, { useReducer, createContext, useContext } from "react"

const GlobalContext = createContext(null)

const initialState = {
  sideNavCollapse: false,
  previousPage: 1,
}

/**
 * @params state and action
 * @returns updated state.
 */

const reducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_SIDENAV_COLLAPSE:
      return {
        ...state,
        sideNavCollapse: !state.sideNavCollapse,
      }
    case ADD_PAGE_NUMBER_USER:
      return {
        ...state,
        previousPage: action?.payload?.data,
      }
  }
}

const GlobalContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  )
}
export default GlobalContextProvider

/**
 *
 *  using the context
 */

export const useGlobalContext = () => useContext(GlobalContext)
