import moment from "moment"
import { API } from "../api"
import { getSignedObjectUrl } from "../utils"

const download = (url, filename) => {
  const anchor = document.createElement("a")
  anchor.href = url
  anchor.download = filename
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export const downloadFileCRP = async ({
  url,
  filename,
  fileType,
}: {
  url: string
  filename: string
  fileType: string
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const signedUrl = await getSignedObjectUrl(url)
      const req = new XMLHttpRequest()
      req.open("GET", signedUrl, true)
      req.responseType = "blob"
      req.onload = function () {
        const reader = new FileReader()
        const out = new Blob([req.response], { type: fileType })
        reader.readAsDataURL(out)
        const objectUrl = URL.createObjectURL(out)
        download(objectUrl, filename)
        URL.revokeObjectURL(url)
        resolve(true)
      }
      req.send()
    } catch (error) {
      reject(error)
    }
  })
}

export const fetchCsvData = async ({ queryKey }): Promise<any> => {
  const params = {} as any
  const dateString: string =
    queryKey[1].registered &&
    moment(queryKey[1].registered).format("YYYY-MM-DD")
  params.keyword = queryKey[1].name
  params.email = queryKey[1].email
  params.phone = queryKey[1].phone
  params.created_at = dateString
  return API.get(`/generate-csv-data`, { params })
}
