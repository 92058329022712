import { CameraOutlined } from "@ant-design/icons"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 215px;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(70, 150, 196, 0.15);
  border-width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    margin-left: 9px;
    color: rgb(70, 150, 195);
  }
  .anticon-camera {
    color: rgb(70, 150, 195);
    svg {
      font-size: 24px;
    }
  }
  cursor: pointer;
`
export const UploadImageNode = (text) => {
  return (
    <Wrapper>
      <CameraOutlined />
      <span className="text">{text}</span>
    </Wrapper>
  )
}
