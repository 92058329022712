import React from "react"
import styled from "styled-components"
import { t } from "i18next"

interface iProps {
  navTitle?: string
  height?: string
  width?: string
  background?: string
  className?: string
  counter?: number
}

const NavTitleWrapper = styled.div<iProps>`
  margin: 30px 30px 0 30px;
  position: relative;
  * {
    font-family: "BIZ UDGothic", sans-serif;
  }
  .counter {
    position: absolute;
    display: flex;
    width: 42px;
    height: 42px;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    background: #e26e30;
    color: #fff;
    top: -22px;
    left: -15px;
    @media (max-width: 576px) {
      width: 32px;
      height: 32px;
    }
  }
  .subtitle-text {
    background: ${(props) => (props.background ? props.background : null)};
    height: ${(props) => (props.height ? props.height : "fit-content")};
    width: ${(props) => (props.width ? props.width : "auto")};
    display: flex;
    justify-content: center;
    padding-inline: 15px;
    text-align: center;
    color: #000000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  @media (max-width: 576px) {
    margin: 20px 20px 0 20px;
  }
`

export const ConsultationSubHeaderComponent: React.FC<iProps> = ({
  navTitle,
  className,
  background,
  height,
  width,
  counter,
}) => {
  return (
    <NavTitleWrapper
      className={className}
      background={background}
      height={height}
      width={width}
    >
      {!!counter && <div className="counter">{counter}</div>}
      <div className="subtitle-text">{t(navTitle) || ""}</div>
    </NavTitleWrapper>
  )
}
