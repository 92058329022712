import { NextPage } from "next"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { ConsultationHeaderComponent } from "../ConsultationHeader"

interface iProps {
  notes?: string
  className?: string
}

const Container = styled.div`
  * {
    font-family: "BIZ UDGothic", sans-serif;
  }
  margin: auto;
  padding: 20px;
  .content-section {
    padding-top: 20px;
    color: rgba(49, 49, 49, 0.8);
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
    white-space: break-spaces;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
`

const SpecialNotesSection: NextPage<iProps> = ({ notes }) => {
  const { t } = useTranslation()

  return (
    <>
      <Container>
        <ConsultationHeaderComponent
          navTitle={"Special Notes /\n Individual proposals"}
        />
        <div className="content-section">{t(notes || "")}</div>
      </Container>
    </>
  )
}

export { SpecialNotesSection }
