import React, { useEffect, useRef, useState } from "react"
import { OptionFees } from "../ConsultationMemoUI "
import { getSignedObjectUrl } from "../../../utils"
import { BaseConsultationImage } from "../../../utils/constant"
import { Carousel, Skeleton } from "antd"
import styled from "styled-components"
import { ConsultationHeaderComponent } from "../ConsultationHeader"
import { t } from "i18next"

const CarouselComponent = styled(Carousel)`
  padding-bottom: 20px;
  > .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid black;
    background: #dbdbdb;
  }
  > .slick-dots li.slick-active button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #000000;
  }
`
const SliderWrapper = styled.div`
  color: #000;
  position: relative;

  & > img {
    width: 335px;
    object-fit: fit-content;
  }
`
const RecomedationOptions = styled.div`
  .title-text {
    font-family: "BIZ UDGothic", sans-serif;
    height: fit-content;
    color: #313131;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    white-space: pre-line;
    font-style: normal;
    font-weight: 700;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    @media (max-width: 986px) {
      font-size: 18px;
      line-height: 22px;
      white-space: pre-line;
    }
  }
  .dash {
    margin-top: 9px;
    height: 2px;
    width: 57px;
    background: #b752ef;
  }
  .text-title {
    font-size: 18px;
    font-family: "BIZ UDGothic", sans-serif;
    color: #313131;
    text-align: center !important;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
`

const CeremonyHall: React.FC<{ data: any }> = ({ data }) => {
  const [desireImages, setDesireImages] = useState<Record<
    string,
    string
  > | null>({})
  const [proposedVenueImage, setProposedVenueImage] = useState("")
  const [proposedVenueImage2, setProposedVenueImage2] = useState("")
  const slider = useRef(null)

  const sortedDesiredOptions = [
    "お料理(通夜料理)",
    "お料理(忌中料理)",
    "返礼品・会葬品",
    "会葬御礼品",
    "喪主花・生花",
    "fresh_flower",
    "供花",
    "embalming",
    "湯灌",
  ]

  const fetchProposedVenueImage = async (option) => {
    if (data?.proposed_venue) {
      const imageUrl = await getSignedObjectUrl(
        `${BaseConsultationImage}/porposed_venue/${option}.jpg`
      )
      setProposedVenueImage(imageUrl)
    }
  }

  const fetchProposedVenueImage2 = async (option) => {
    if (data?.proposed_venue_1) {
      const imageUrl = await getSignedObjectUrl(
        `${BaseConsultationImage}/porposed_venue/${option}.jpg`
      )
      setProposedVenueImage2(imageUrl)
    }
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      if (data?.desired_option && data?.desired_option.length) {
        data?.desired_option?.map(async (item) => {
          const imageUrl = await getSignedObjectUrl(
            `${BaseConsultationImage}/desired_option/${item}.jpg`
          )
          setDesireImages((prev) => {
            if (prev) return { ...prev, [item]: imageUrl }
            return { [item]: imageUrl }
          })
        })
      }
      await fetchProposedVenueImage(data?.proposed_venue)
      await fetchProposedVenueImage2(data?.proposed_venue_1)
      setIsLoading(false)
    })()
    return () => {
      setDesireImages({})
    }
  }, [data])
  if (isLoading) {
    return (
      <OptionFees ref={slider.current}>
        <ConsultationHeaderComponent
          navTitle={t("Option Fees")}
          sortingOrder={"B"}
          sortOrderBackground={"#DDABF9;"}
          // subTitle={t("Desired Options")}
          description="オプション費用とはお客様のご希望やご要望に合わせて基本費用に追加される費用です。故人様らしいご葬儀を実現するためにご提案させていただきます。"
        />
        <Skeleton
          paragraph={{ rows: 10 }}
          style={{ padding: "0 10px" }}
          active
        />
      </OptionFees>
    )
  }
  const hasBothImages: boolean = !!proposedVenueImage && !!proposedVenueImage2
  return (
    <OptionFees ref={slider.current}>
      <ConsultationHeaderComponent
        navTitle={t("Option Fees")}
        subTopic={
          data?.proposed_venue || data?.proposed_venue_1 ? "式場利用料" : null
        }
        sortingOrder={"B"}
        sortOrderBackground={"#DDABF9;"}
        dotsForOptionFees
        description="オプション費用とはお客様のご希望やご要望に合わせて基本費用に追加される費用です。故人様らしいご葬儀を実現するためにご提案させていただきます。"
        // subTitle={t("Desired Options")}
      />
      {(!!data?.proposed_venue || !!data?.proposed_venue_1) && (
        <>
          {hasBothImages ? (
            <>
              <div className="static-info">{"右にスライドでさらに表示"}</div>
              <CarouselComponent
                style={{
                  width: "335px",
                }}
                dotPosition="top"
                autoplay={false}
                dots
                ref={slider}
                swipeToSlide
                draggable
              >
                <SliderWrapper>
                  <img
                    className="option-fee-img"
                    src={proposedVenueImage}
                    alt="proposed_venue_image"
                  />
                </SliderWrapper>
                <SliderWrapper>
                  <img
                    className="option-fee-img"
                    src={proposedVenueImage2}
                    alt="proposed_venue_image"
                  />
                </SliderWrapper>
              </CarouselComponent>
            </>
          ) : (
            <div
              style={{
                width: "335px",
                paddingBottom: "20px",
              }}
            >
              <SliderWrapper>
                <img
                  className="option-fee-img"
                  src={proposedVenueImage || proposedVenueImage2}
                  alt="proposed_venue_image"
                />
              </SliderWrapper>
            </div>
          )}
        </>
      )}
      {!!data?.desired_option && desireImages && (
        <RecomedationOptions>
          <div className="title-text">
            <span className="dash"></span>
            <span className="text-title">{"おすすめのオプション"}</span>
            <span className="dash"></span>
          </div>
          <div className="grid-img">
            {sortedDesiredOptions?.map((img, index) => {
              if (Object.keys(desireImages)?.includes(img)) {
                return (
                  <img
                    src={desireImages?.[img]}
                    key={index}
                    loading="lazy"
                    alt={"desire-option-image"}
                  />
                )
              }
            })}
          </div>
        </RecomedationOptions>
      )}
    </OptionFees>
  )
}

export { CeremonyHall }
