import { API } from "../api"

export const getSignedObjectUrl = (url: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    // const params = {
    //   object: url,
    // }
    API.post(`/utils/signed-url?object=${url}`)
      .then((response) => {
        if (response?.data?.signed_url) {
          resolve(response?.data?.signed_url)
        } else {
          resolve(null)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
