import React from "react"
import styled from "styled-components"

interface Props {
  title?: string
  disabled?: boolean
  showIcon?: boolean
  bold?: boolean
}

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-self: flex-start;
`
const CircleDiv = styled.div<{ disabled: boolean }>`
  height: 15px;
  min-width: 15px;
  background-color: ${({ disabled }) =>
    disabled ? "rgba(226, 110, 48, 0.2)" : "#E26E30"};
  border-radius: 50%;
  margin-right: 10px;
  align-self: center;
`
const Title = styled.div<{ bold: boolean }>`
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`

const Label: React.FC<Props> = ({ title, disabled, showIcon, bold }) => {
  return (
    <LabelContainer>
      {showIcon && <CircleDiv disabled={disabled} />}
      <Title bold={bold}>{title}</Title>
    </LabelContainer>
  )
}

export { Label }
