import { NextPage } from "next"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { ConsultationHeaderComponent } from "../ConsultationHeader"
import { useFormik } from "formik"
import * as yup from "yup"
import {
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../../../../user/components/organisms/InviteFamilyForm/helper"
import { Button, TextField } from "../../atom"
import { theme } from "../../../theme"
import { PlusCircleOutlined } from "@ant-design/icons"
import { ButtonComponent } from "../../atom/Button"
import { useMutation } from "react-query"
import { notification } from "antd"
import router from "next/router"
import { API } from "../../../api"
import moment from "moment"

interface iProps {
  freeNotes?: string
  isOnlyPreview?: boolean
  id?: string
  data?: any
  className?: string
}
const Main = styled.div`
  background-color: #fff;
`
const Container = styled.div`
  * {
    font-family: "BIZ UDGothic", sans-serif;
  }
  margin: auto;
  padding: 20px 20px 16px 20px;

  .content-section {
    padding-top: 20px;
  }
  .sp-center {
    display: flex;
    justify-content: center;
  }
  .text-field-2 {
    .ant-input {
      height: 180px !important;

      @media (max-width: 986px) {
        height: 180px !important;
      }
    }
  }
`
const ButtonWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  justify-content: center;
`

const FreeNotesSection: NextPage<iProps> = ({
  isOnlyPreview,
  freeNotes,
  id,
  data,
}) => {
  const createConsultMemo = async (values): Promise<any> => {
    return API.post(`/consultations`, values)
  }

  const updateConsultMemo = async (values) => {
    const id = values.id
    return API.put(`/consultations/${id}`, values)
  }
  const { t } = useTranslation()
  const [textFields, setTextFields] = useState(
    data?.free_notes || [{ note: "" }]
  ) // Initial item

  const handleTextFieldChange = (index, evt) => {
    const newTextField = [...textFields]
    newTextField[index][evt.target.name] = evt.target.value
    setTextFields(newTextField)
    if (newTextField?.length > 0) {
      formik?.setFieldValue("free_notes", newTextField)
    }
  }

  const validationSchema = yup.object().shape({
    title: yup.string(),
  })

  const formik = useFormik<any>({
    initialValues: {
      id: id || "",
      free_notes: freeNotes || [],
      // remaining data also sent during onsubmit
      user_id: data?.user_id,
      consultation_date:
        moment(data?.consultation_date, "YYYY年MM月DD日")
          .format("YYYY-MM-DDTHH:mm:ss[Z]")
          .split("T")[0] || "",
      consultation_title: data?.consultation_title,
      denomination: data?.denomination,
      desired_option: data?.desired_option,
      farewell_ceremony: data?.farewell_ceremony,
      funeral_mourners: data?.funeral_mourners,
      funeral_relatives: data?.funeral_relatives,
      material: data?.material || null,
      notice: data?.notice,
      officiant_introduction: data?.officiant_introduction,
      proposed_venue: data?.proposed_venue,
      proposed_venue_1: data?.proposed_venue_1,
      religion: data?.religion,
      sunlife_subscription: data?.sunlife_subscription,
      manager_image: data?.manager_image,
      user_name: data?.user_name,
      hint: data?.hint,
      select_officiant: data?.select_officiant,
      join_intension: data?.join_intension,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate(values)
    },
  })

  const handleAddTextField = () => {
    const newTextField = [...textFields, { note: "" }]
    setTextFields(newTextField)
  }

  const { mutate, isLoading: isMutating } = useMutation(
    id ? updateConsultMemo : createConsultMemo,
    {
      onSuccess: () => {
        notification.success({
          message: id
            ? t("Consultation Memo updated successfully")
            : t("Consultation Memo added successfully"),
        })
        router.push(`/consultation-memo/admin`)
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )

  return (
    <Main>
      <Container>
        <ConsultationHeaderComponent navTitle={"Use free Note"} />
        <div className="content-section">
          {textFields.map((textField, index) => (
            <TextFieldContainer key={index}>
              <LabelContainer>
                <Label>{t("Free notes")}</Label>
              </LabelContainer>
              <TextField
                className="text-field-2"
                placeholder={t("Enter your remarks")}
                name="note"
                error={formik.touched.free_notes && formik.errors?.free_notes}
                onChange={(e) => handleTextFieldChange(index, e)}
                onBlur={formik.handleBlur}
                value={textField?.note}
                disableboxshadow
                borderradius="10px"
                type="textarea"
                maxLength={500}
                rows={4}
                showCounting
                disabled={isOnlyPreview}
              />
            </TextFieldContainer>
          ))}
          {!isOnlyPreview && (
            <div>
              <div className="sp-center">
                <Button
                  width="216px"
                  background={theme.base}
                  color={theme.text.color.light}
                  borderradius="10px"
                  className="rightBtn"
                  fontSize="18px"
                  height="50px"
                  onClick={handleAddTextField}
                >
                  {t("Add  Free Notes")}
                  <PlusCircleOutlined className="icon" />
                </Button>
              </div>
              <ButtonWrapper>
                <ButtonComponent
                  htmlType="submit"
                  height="60px"
                  width="240px"
                  background={theme.button.primary}
                  onClick={() => formik.handleSubmit()}
                  loading={isMutating}
                >
                  {t("Update")}
                </ButtonComponent>
              </ButtonWrapper>
            </div>
          )}
        </div>
      </Container>
    </Main>
  )
}

export { FreeNotesSection }
