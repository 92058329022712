/* eslint-disable no-unused-vars */
import React from "react"
import { Upload, notification, message } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

interface FileUploadProps {
  value?: any
  maxCount?: number
  onChange?: (any) => void
  className?: string
  children: React.ReactNode
  singleUpload?: boolean
}

const UploadComponent = styled(Upload)`
  width: 100%;

  & > .ant-upload {
    width: 100%;
  }

  .ant-upload-list-item-card-actions-btn {
    opacity: 1 !important;
  }
`

const FileUpload: React.FC<FileUploadProps> = ({
  maxCount,
  onChange,
  value,
  children,
  className,
  singleUpload,
}) => {
  const { t } = useTranslation()

  const handleBeforeUpload = (file) => {
    const fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/tiff"]
    if (!fileTypes.includes(file.type)) {
      notification.error({
        message: t("You can only upload JPG/PNG/TIFF file!"),
      })
      return Upload.LIST_IGNORE
    }

    const isLt10M = file.size / 1024 / 1024 < 10
    if (!isLt10M) {
      notification.error({
        message: t(
          "Only jpg,jpeg,png,tiff format images within 10mb can be uploaded."
        ),
      })

      return Upload.LIST_IGNORE
    }

    if (file.status === "error") {
      notification.error({
        message: t("Error uploading files"),
      })

      return Upload.LIST_IGNORE
    }
    return true
  }

  const handleChange = ({ file }) => {
    //Handling the max count for upload
    if (
      !singleUpload &&
      file.status === "uploading" &&
      value.length === maxCount
    ) {
      notification.error({
        message: t(`Cannot upload more than ${maxCount} files`),
      })
    }

    if (file.status === "done") {
      singleUpload ? onChange(file) : onChange([...value, file])
    } else if (file.status === "removed") {
      onChange(
        value.filter((e) => {
          return file.uid !== e.uid
        })
      )
    }
  }

  return (
    <UploadComponent
      showUploadList={true}
      defaultFileList={singleUpload ? value?.name : value}
      onChange={handleChange}
      beforeUpload={(file) => handleBeforeUpload(file)}
      accept="image/jpeg, image/jpg, image/png, image/tiff"
      maxCount={maxCount}
      className={className}
    >
      {children}
    </UploadComponent>
  )
}

export { FileUpload }
