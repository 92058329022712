import { NextPage } from "next"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { ConsultationHeaderComponent } from "../ConsultationHeader"
import { getSignedObjectUrl } from "../../../utils"

interface iProps {
  className?: string
  fileData?: any
}

const Main = styled.main`
  background: #fffded;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`
const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  .bordered:not(:last-child) {
    border-bottom: 1px solid #dadada;
    margin: 0px 20px;
  }
`
const ContentWrapper = styled.div`
  padding: 16px 0px;
  * {
    font-family: "BIZ UDGothic", sans-serif;
  }
  .file-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }
  .serial-number {
    display: flex;
    width: 22px;
    min-width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #000000;
    font-size: 18px;
    font-weight: 400;
    .serial-content {
      color: #313131;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .file-name-class {
    color: var(--dark_gray, #313131);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    word-break: break-all;

    .link-class {
      color: #e26e30;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
  .padding-left {
    padding-left: 0;
    @media (max-width: 576px) {
      padding-left: 0;
    }
  }
  .download-btn {
    display: none;
    border-radius: 4px;
    background: #e26e30;
    padding: 6px 12px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    & > span {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    @media (max-width: 576px) {
      display: none;
    }
  }

  .download-btn-sp {
    border-radius: 4px;
    background: #e26e30;
    display: flex;
    padding: 6px 12px;
    align-items: flex-end;
    gap: 8px;
    cursor: pointer;
  }
  .fl-row {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    @media (max-width: 576px) {
      align-items: flex-start;
    }
  }
  .flex-sp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 5px;
    }
  }
`

const RelatedMaterialSection: NextPage<iProps> = ({ fileData }) => {
  const { t } = useTranslation()
  const [fileList, setFileList] = useState([])

  const prefix = `https://storage.googleapis.com/${process?.env?.NEXT_PUBLIC_GCP_STORAGE_BUCKET_NAME}/images/materials/`

  const handleDownload = (url, name) => {
    const splittedName = name?.split("-KO|")[1] || ""

    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        const a = document.createElement("a")
        a.href = URL.createObjectURL(b)
        a.setAttribute("download", splittedName)
        a.click()
      })
    })
  }

  useEffect(() => {
    ;(async () => {
      const updatedFileList = []
      const file = fileData
      if (file?.length > 0) {
        for (let i = 0; i < file.length; i++) {
          if (file[i]?.proposal_material?.startsWith(prefix)) {
            const imageUrl = await getSignedObjectUrl(
              fileData[i]?.proposal_material
            )

            updatedFileList.push({
              name: fileData[i]?.proposal_material || "",
              url: imageUrl,
              material_title: fileData[i]?.material_title || "",
            })
          } else {
            updatedFileList.push({
              name: fileData[i]?.proposal_material || "",
              url: fileData[i]?.proposal_material || "",
              material_title: fileData[i]?.material_title || "",
            })
          }
        }
        setFileList(() => [...updatedFileList])
      }
    })()
  }, [fileData])
  if (fileData === null) {
    return null
  }

  if (fileList && fileList.length === 1) {
    if (
      !(fileList[0]?.material_title || fileList[0]?.url || fileList[0]?.name)
    ) {
      return null
    }
  }
  return (
    <Main>
      <Container>
        <ConsultationHeaderComponent navTitle={"Related Materials"} />
        {fileList?.map((data, index) => {
          return data?.material_title || data?.url || data?.name ? (
            <>
              <ContentWrapper key={index}>
                <div className="file-content">
                  <div className="fl-row">
                    <span className="serial-number">
                      <span className="serial-content">{index + 1}</span>
                    </span>
                    <div className="flex-sp">
                      <span className="file-name-class">
                        {data?.material_title}
                      </span>

                      <div className="file-name-class padding-left">
                        {data?.name?.startsWith(prefix) ? (
                          <span style={{ color: "#313131CC" }}>
                            {data?.name?.split("-KO|")[1] || ""}
                          </span>
                        ) : (
                          <a
                            className="link-class"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              data?.name?.startsWith("http")
                                ? data?.name
                                : `https://${data?.name}`
                            }
                          >
                            {data?.name}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  {data?.url?.startsWith(prefix) && (
                    <div
                      className="download-btn"
                      onClick={() =>
                        handleDownload(
                          fileList[index]?.url,
                          fileList[index]?.name
                        )
                      }
                    >
                      <span className="icon_svg">
                        <img
                          src="/assets/icons/downloadnew.svg"
                          alt={"download"}
                        />
                      </span>
                      <span>{t("Download")}</span>
                    </div>
                  )}
                  {data?.url?.startsWith(prefix) && (
                    <div
                      className="download-btn-sp"
                      onClick={() =>
                        handleDownload(
                          fileList[index]?.url,
                          fileList[index]?.name
                        )
                      }
                    >
                      <span className="icon_svg">
                        <img
                          src="/assets/icons/downloadnew.svg"
                          alt={"download"}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </ContentWrapper>
              <div className="bordered"></div>
            </>
          ) : null
        })}
      </Container>
    </Main>
  )
}

export { RelatedMaterialSection }
