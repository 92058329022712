import React from "react"
import styled from "styled-components"
import { ConfigProvider, DatePicker } from "antd"
import jaJP from "antd/lib/locale/ja_JP"
import { i18next } from "../../../i18n"
import "moment/locale/ja"
import moment from "moment"
moment.locale("ja")

interface iDatePicker {
  showTime?: object | boolean
  width?: string
  placeholder?: string
  className?: string
  height?: string
  format?: string
  borderRadius?: string
  allowClear?: boolean
  value?: moment.Moment
  disabled?: boolean
  style?: object
  onChange?: (moment, string) => void
}

const Wrapper = styled.div`
  display: contents;
`

const StyledDatePicker = styled(DatePicker)`
  width: ${({ width }: iDatePicker) => (width ? width : "360px")};
  height: ${({ height }: iDatePicker) => (height ? height : "44px")};
  border-radius: ${({ borderRadius }: iDatePicker) =>
    borderRadius ? borderRadius : "10px"};
  &:hover {
    border-color: #8b94a5;
  }
  .ant-picker-input > input {
    font-size: 16px;

    @media (max-width: 786px) {
      font-size: 16px;
    }
  }
`

const DatePickerComponent: React.FC<iDatePicker> = ({
  placeholder,
  showTime,
  className,
  format,
  disabled = false,
  allowClear = true,
  value,
  style,
  onChange,
}) => {
  return (
    <Wrapper className={className}>
      <ConfigProvider locale={i18next.language === "ja" && jaJP}>
        <StyledDatePicker
          showTime={showTime}
          placeholder={placeholder ?? ""}
          format={format}
          disabled={disabled}
          onChange={onChange}
          allowClear={allowClear}
          value={value}
          style={style}
        />
      </ConfigProvider>
    </Wrapper>
  )
}

export { DatePickerComponent }
