import React from "react"
import styled from "styled-components"
import { t } from "i18next"

interface iProps {
  navTitle?: string
  sortingOrder?: string
  height?: string
  width?: string
  background?: string
  className?: string
  isNoMargin?: boolean
  sortOrderBackground?: string
  subTitle?: string
  dotsForDenomination?: boolean
  dotsForOptionFees?: boolean
  subTopic?: string
  description?: string
}

const NavTitleWrapper = styled.div<iProps>`
  /* margin-top: ${(props) => (props.isNoMargin ? "0px" : "10px")};d */
  .title-text {
    font-family: "BIZ UDGothic", sans-serif;
    background: ${(props) => (props.background ? props.background : null)};
    height: ${(props) => (props.height ? props.height : "fit-content")};
    width: ${(props) => (props.width ? props.width : "auto")};
    color: #313131;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    white-space: pre-line;
    font-style: normal;
    font-weight: 700;
    display: flex;
    justify-content: center;
    gap: 10px;

    @media (max-width: 986px) {
      font-size: 18px;
      line-height: 22px;
      white-space: pre-line;
    }
  }
  .sub-topic {
    margin: 20px 0 0;
  }
  .dots,
  .dots-sort {
    color: ${(props) =>
      props.dotsForDenomination
        ? "#1bab56"
        : props.dotsForOptionFees
        ? "#B752EF"
        : "#e26e30"};
    margin-top: 0px;
    font-size: 10px;
  }
  .dots {
    margin-top: -4px;
  }
  .dash {
    margin-top: 9px;
    height: 2px;
    width: 57px;
    background: ${(props) =>
      props.dotsForDenomination
        ? "#1bab56"
        : props.dotsForOptionFees
        ? "#B752EF"
        : "#e26e30"};
  }

  .text-title,
  .text-sort-title {
    font-size: 18px;
    font-family: "BIZ UDGothic", sans-serif;
    color: #313131;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
  .text-sort-title {
    font-size: 36px;
    line-height: 36px;
    width: 252px;
  }
  .sort-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
  .sort-order {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: ${(props) =>
      props.sortOrderBackground ? props.sortOrderBackground : "#ddabf9"};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }
`
const SubTitle = styled.div`
  font-family: "BIZ UDGothic", sans-serif;
  color: rgba(49, 49, 49, 0.8);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  padding: 0px 10px;
`

const Description = styled.div`
  font-family: "BIZ UDGothic", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 12px;
`

export const ConsultationHeaderComponent: React.FC<iProps> = ({
  navTitle,
  sortingOrder,
  className,
  background,
  height,
  width,
  isNoMargin,
  sortOrderBackground,
  subTitle,
  dotsForDenomination,
  dotsForOptionFees,
  subTopic,
  description,
}) => {
  console
  return (
    <NavTitleWrapper
      className={className}
      background={background}
      height={height}
      width={width}
      isNoMargin={isNoMargin}
      sortOrderBackground={sortOrderBackground}
      dotsForDenomination={dotsForDenomination}
      dotsForOptionFees={dotsForOptionFees}
    >
      {sortingOrder ? (
        <>
          <div className="sort-wrapper">
            <div className="sort-order">{t(sortingOrder) || ""}</div>
          </div>

          <div className="title-text">
            <span className="dots-sort">.......</span>
            <span className="text-sort-title">{t(navTitle) || ""}</span>
            <span className="dots-sort">.......</span>
          </div>
        </>
      ) : (
        <div className="title-text">
          <span className="dots">.......</span>
          <span className="text-title">{t(navTitle) || ""}</span>

          <span className="dots">.......</span>
        </div>
      )}
      {description && <Description>{description}</Description>}
      {!!subTopic && (
        <div className="sub-topic">
          <div className="title-text">
            <span className="dash"></span>
            <span className="text-title">{t(subTopic) || ""}</span>
            <span className="dash"></span>
          </div>
        </div>
      )}
      {!!subTitle && <SubTitle>{subTitle}</SubTitle>}
    </NavTitleWrapper>
  )
}
