import { NextPage } from "next"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

interface iProps {
  image?: any
  title?: string
  subTitle?: string
  className?: string
}

const Container = styled.div`
  @media (max-width: 986px) {
    width: 100%;
  }
  .card-container {
    display: flex;
    width: 350px;
    padding: 12px 16px;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    border-radius: 12px;
    background: rgba(244, 243, 238, 0.8);
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1) inset;
    img {
      width: 75px;
      height: 75px;
    }
    @media (max-width: 986px) {
      width: 100%;
    }
  }

  .content {
    color: var(--dark_gray, #313131);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: right;
    letter-spacing: 3px;
  }
  .sub-content {
    color: #313131;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    text-align: end;
    letter-spacing: 4px;
  }
`

const BannerCardSection: NextPage<iProps> = ({ image, title, subTitle }) => {
  const { t } = useTranslation()

  return (
    <>
      <Container>
        <div className="card-container">
          <div className="image">{image}</div>
          <div>
            <div className="content">{t(title) || ""}</div>
            <div className="sub-content">{`${subTitle}名`}</div>
          </div>
        </div>
      </Container>
    </>
  )
}

export { BannerCardSection }
