import React, { useEffect, useState } from "react"
import { BannerImage, FuneralBasicExpenses } from "../ConsultationMemoUI "
import { getSignedObjectUrl } from "../../../utils"
import { BaseConsultationImage } from "../../../utils/constant"
import { Skeleton } from "antd"
import { ConsultationHeaderComponent } from "../ConsultationHeader"
import { t } from "i18next"

interface IFuneralBasic {
  data?: any
}

const FuneralBasic: React.FC<IFuneralBasic> = ({ data }) => {
  const ImageURL = `${BaseConsultationImage}/fareware_ceremony/${data?.farewell_ceremony}.png`
  const SubscriptionImage = `${BaseConsultationImage}/subscription/オンライン入会バナー.png`
  const BannerImage2 = `${BaseConsultationImage}/static_banner/banner2.png`

  const [bannerImageUrl, setBannerImgUrl] = useState<string | null>(null)
  const [bannerImage2Url, setBannerImg2Url] = useState<string | null>(null)
  const [subImageUrl, setSubImgUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      if (ImageURL && BannerImage2) {
        const imageUrl = await getSignedObjectUrl(ImageURL)
        setBannerImgUrl(imageUrl)
        const image2Url = await getSignedObjectUrl(BannerImage2)
        setBannerImg2Url(image2Url)
      }
      if (data?.sunlife_subscription === 0) {
        const subImageUrl = await getSignedObjectUrl(SubscriptionImage)
        setSubImgUrl(subImageUrl)
      }
      setIsLoading(false)
    })()
    return () => {
      setBannerImgUrl(null)
    }
  }, [ImageURL, data, BannerImage2])
  if (isLoading) {
    return (
      <div className="flex-center">
        <FuneralBasicExpenses>
          <ConsultationHeaderComponent
            navTitle={t("ご葬儀基本費用")}
            sortingOrder={"A"}
            sortOrderBackground={"#FFD5C2"}
            description="基本費用とは、セレモニーを行う上で必要な物品やサービスにかかる費用のことです。サン・ライフのセットプランには、棺、骨壺、霊柩車など必要なものが含まれています。 （お別れ式プランを除き、プランは会員価格で表示しております）"
          />
          <Skeleton paragraph={{ rows: 10 }} active />
        </FuneralBasicExpenses>
      </div>
    )
  }

  if (data?.farewell_ceremony) {
    return (
      <div className="flex-center">
        <FuneralBasicExpenses>
          <ConsultationHeaderComponent
            navTitle={t("ご葬儀基本費用")}
            sortingOrder={"A"}
            sortOrderBackground={"#FFD5C2"}
            description="基本費用とは、セレモニーを行う上で必要な物品やサービスにかかる費用のことです。サン・ライフのセットプランには、棺、骨壺、霊柩車など必要なものが含まれています。 （お別れ式プランを除き、プランは会員価格で表示しております）"
          />
          {data?.farewell_ceremony !== "無宗教式" && (
            <img src={bannerImageUrl} loading="lazy" alt={"funeral-ceremony"} />
          )}

          {data?.sunlife_subscription === 0 ? (
            <a href={"https://online.sunlife.jp/"} target="_blank">
              <BannerImage>
                <div className="title">{"オンライン入会"}</div>
                <img
                  className="dummy-img"
                  src={subImageUrl}
                  loading="lazy"
                  alt={"subcription-image"}
                />
              </BannerImage>
            </a>
          ) : (
            <a
              href={"https://www.sunlife.jp/members/contact/index3.html"}
              target="_blank"
            >
              <BannerImage>
                <div className="title">{"サン・ライフメンバーズ"}</div>
                <img
                  className="dummy-img"
                  src={bannerImage2Url}
                  loading="lazy"
                  alt="banner-imag-2"
                />
              </BannerImage>
            </a>
          )}
        </FuneralBasicExpenses>
      </div>
    )
  }
  return null
}

export { FuneralBasic }
