import React, { useEffect, useState } from "react"
import { BannerImage, HintComp } from "../ConsultationMemoUI "
import { BaseConsultationImage } from "../../../utils/constant"
import { getSignedObjectUrl } from "../../../utils"
import { Skeleton, Image } from "antd"
import { EyeOutlined } from "@ant-design/icons"
import { t } from "i18next"

const FinalBannerSection: React.FC<{ data?: any }> = ({ data }) => {
  const BannerImage3 = `${BaseConsultationImage}/static_banner/banner3.png`
  const BannerImage4 = `${BaseConsultationImage}/static_banner/banner4.png`

  const [bannerImage3Url, setBannerImage3Url] = useState<string | null>(null)
  const [bannerImage4Url, setBannerImage4Url] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      if (BannerImage3 && BannerImage4) {
        const imageUrl = await getSignedObjectUrl(BannerImage3)
        setBannerImage3Url(imageUrl)
        const image2Url = await getSignedObjectUrl(BannerImage4)
        setBannerImage4Url(image2Url)
      }
      setIsLoading(false)
    })()
    return () => {
      setBannerImage3Url(null)
      setBannerImage4Url(null)
    }
  }, [data])
  if (isLoading) {
    return (
      <HintComp>
        <Skeleton active />
      </HintComp>
    )
  }

  return (
    <HintComp>
      <a href={"https://sunlife.actibookone.com/"} target="_blank">
        <BannerImage>
          <div className="title">{"他の式場やプランの資料はこちら "}</div>
          <img
            className="dummy-img"
            src={bannerImage3Url}
            alt="banner-image-3"
          />
        </BannerImage>
      </a>
      <BannerImage>
        <div className="title">{"クーポン "}</div>
        <Image
          className="dummy-img"
          src={bannerImage4Url}
          alt="banner-image-4"
          preview={{
            mask: (
              <span>
                <EyeOutlined />
                &nbsp;
                {t("Preview")}
              </span>
            ),
          }}
        />
      </BannerImage>
      {/* <div className="hint">
        {data?.hint ||
          "＊他のクーポン（LINEクーポン）と併用して利用すること\nはできません"}
      </div> */}

      {data?.sunlife_subscription === 0 &&
      data?.join_intension &&
      Number(data?.join_intension) === 1 ? (
        <BannerImage>
          <div className="title">{t("Member temp card")}</div>
          <Image
            className="dummy-img join-intension-img"
            src="/assets/imgs/intention.jpg"
            alt="banner-image-5"
            preview={{
              mask: (
                <span>
                  <EyeOutlined />
                  &nbsp;
                  {t("Preview")}
                </span>
              ),
            }}
          />
        </BannerImage>
      ) : null}
    </HintComp>
  )
}

export { FinalBannerSection }
