import { ConfigProvider, Pagination } from "antd"
import React from "react"
import styled from "styled-components"
import jaJP from "antd/lib/locale/ja_JP"
import { i18next } from "../../../i18n"
import { t } from "i18next"

interface iPagination {
  className?: string
  current?: number
  hideOnSinglePage?: boolean
  total?: number
  showSizeChanger?: boolean
  pageSize?: number
  onChange?: (page: number, pageSize: number) => void
  onShowSizeChange?: (current: number, size: number) => void
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
const StyledPagination = styled(Pagination)``

const StyledPaginationComponent: React.FC<iPagination> = ({
  current,
  hideOnSinglePage = true,
  showSizeChanger = false,
  pageSize = 10,
  onChange,
  onShowSizeChange,
  total,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <ConfigProvider locale={i18next.language === "ja" && jaJP}>
        <StyledPagination
          current={current}
          hideOnSinglePage={hideOnSinglePage}
          total={total}
          showSizeChanger={showSizeChanger}
          pageSize={pageSize}
          onChange={onChange}
          onShowSizeChange={onShowSizeChange}
          showTotal={(total, range) =>
            `${t("paginationKey", {
              range: `${range[0]}-${range[1]}`,
              total: `${total}`,
            })}`
          }
        />
      </ConfigProvider>
    </Wrapper>
  )
}

export { StyledPaginationComponent }
