import { CalendarOutlined } from "@ant-design/icons"
import moment from "moment"
import { NextPage } from "next"
import React from "react"
import styled from "styled-components"

interface iProps {
  date?: string
  consultTitle?: string
  consultation?: string
  corresponding?: string
  className?: string
}

const Container = styled.div`
  width: 375px;
  background: #e26e30;
  display: flex;
  align-items: center;
  justify-content: center;
  * {
    font-family: "BIZ UDGothic", sans-serif;
  }
  nav {
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 1400px;
    padding: 8px 20px;
    @media (max-width: 986px) {
      padding: 8px 20px;
    }
    @media (max-width: 986px) {
      padding: 8px 20px;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`
const FlexWrapper = styled.div`
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .items {
    margin-bottom: 5px;
    color: #fff;
    font-style: normal;
    font-size: 22px;
    font-weight: 700;
    @media (max-width: 786px) {
      font-size: 22px;
      margin-bottom: 8px;
    }
  }

  .consult-type {
    line-height: 22px;
    text-align: center;
    font-size: 14px;

    @media (max-width: 986px) {
      text-align: center;
      font-size: 14px;
    }
  }
  .item-last {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    @media (max-width: 986px) {
      font-size: 14px;
    }
  }
  .date-picker-shape {
    height: 26px;
    margin-top: 5px;
    width: max-content;
    border-radius: 28px;
    background: #fff;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    .content {
      color: var(--dark_gray, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      @media (max-width: 986px) {
        font-size: 12px;
      }
    }
    @media (max-width: 986px) {
      height: 26px;
      margin-top: 5px;
    }
  }
  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .title-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

const NavSection: NextPage<iProps> = ({ date, consultation }) => {
  return (
    <>
      <Container>
        <nav>
          <FlexWrapper>
            <div className="flex-container">
              <div className="title-flex-center">
                <div className="items font-size">{`${consultation}様の相談メモ`}</div>
              </div>
              <div className="items date-picker-shape">
                {(
                  <span className="content">
                    <CalendarOutlined style={{ color: "#e26e30" }} />
                    &nbsp;{" "}
                    {moment(date).isValid()
                      ? String(moment(date).format("LL"))
                      : moment().format("LL")}
                  </span>
                ) || null}
              </div>
            </div>
          </FlexWrapper>
        </nav>
      </Container>
    </>
  )
}

export { NavSection }
