import Head from "next/head"
import * as Sentry from "@sentry/node"
import { AppProps } from "next/app"
import { useRouter } from "next/router"
import { API, LanguageOption, auth, theme } from "@project/shared"
import { useEffect, useState } from "react"
import { signOut, onAuthStateChanged } from "firebase/auth"
import { message } from "antd"
import { CloseCircleFilled } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { QueryClient, QueryClientProvider } from "react-query"
import { GlobalStyles, AuthProvider } from "../utils"
import "../utils/css-imports"
import { ThemeProvider } from "styled-components"
import GlobalContextProvider from "../context/GlobalContextProvider"
import axios from "axios"

const queryClient = new QueryClient({ defaultOptions: {} })

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled:
      process.env.ENVIRONMENT === "development" ||
      process.env.ENVIRONMENT === "production",
    environment: `kaki-oki-front-admin-${process.env.ENVIRONMENT}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}
const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter()
  const [loading, setLoading] = useState(true)
  const [loginLoading, setLoginLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [loginId, setLoginId] = useState<string>(null)
  const [adminRole, setAdminRole] = useState<string>(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      routers.events.on("routeChangeComplete", () => {
        window.scrollTo(0, 0)
      })
    }
  }, [])
  const initialLoad = () => {
    onAuthStateChanged(auth, async (user) => {
      try {
        if (user !== null) {
          const idToken = await user!.getIdTokenResult()
          setAdminRole(String(idToken.claims["role"]))
          setLoginId(String(idToken.claims.id))
          if (idToken.claims["role"] === "super-admin") {
            setUser(user)
            setIsAdmin(true)
            setLoginLoading(false)
            setLoading(false)
            return
          }
          if (
            idToken.claims["role"] === "system-admin" ||
            idToken.claims["role"] === "general-admin"
          ) {
            const token = await auth.currentUser?.getIdToken(true)
            const res: any = await axios.get(
              `https://api.kaki-oki.com/KtR7xPz2qWnY5sL8m-ip`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                  "Is-Prod": String(
                    process.env.ENVIRONMENT !== "development" &&
                      process.env.ENVIRONMENT !== "local"
                  ),
                },
              }
            )
            // verifying client ip address
            try {
              await API.post("/ip-verify", {
                admin_email: idToken.claims.email,
                admin_ip_address: res?.data?.clientIP,
              })
              setUser(user)
              setIsAdmin(true)
              setLoginLoading(false)
            } catch (error) {
              signOut(auth)
              setUser(null)
              setLoginLoading(false)
              message.error({
                key: "02",
                content: t(
                  error.data.error.error === "IP Denied"
                    ? "You cannot access this page. Please contact your administration"
                    : error.data.error.error
                ),
                icon: (
                  <CloseCircleFilled onClick={() => message.destroy("02")} />
                ),
              })
              return
            }
          } else {
            signOut(auth)
            setUser(null)
            setLoginLoading(false)
            message.error({
              key: "01",
              icon: <CloseCircleFilled onClick={() => message.destroy("01")} />,
              content: t("Unauthorized user"),
            })
          }
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        Sentry.captureException(error)
        message.error({
          key: "02",
          content: t("An error has occurred. Please try again later."),
          icon: <CloseCircleFilled onClick={() => message.destroy("02")} />,
        })
      }
    })
  }

  useEffect(() => {
    initialLoad()
  }, [])

  return (
    <>
      <Head>
        <title>{`${t("Admin")} | かきおき`}</title>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <GlobalContextProvider>
          <ThemeProvider theme={theme}>
            <AuthProvider
              loading={loading}
              user={user}
              isAdmin={isAdmin}
              setUser={setUser}
              adminRole={adminRole}
              setAdminRole={setAdminRole}
              loginId={loginId}
              setLoginId={setLoginId}
              loginLoading={loginLoading}
              setLoginLoading={setLoginLoading}
            >
              <Component {...pageProps} />
              {process.env.ENVIRONMENT === "development" ||
              process.env.ENVIRONMENT === "local" ? (
                <LanguageOption />
              ) : (
                <></>
              )}
            </AuthProvider>
          </ThemeProvider>
        </GlobalContextProvider>
      </QueryClientProvider>
    </>
  )
}

export default MyApp

export const getInitialProps = () => {
  return { props: { secret_key: process.env.IP_ADDRESS_SECRET_KEY } }
}
