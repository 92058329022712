import { NextPage } from "next"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ConsultationHeaderComponent } from "../ConsultationHeader"
import { t } from "i18next"
import { getSignedObjectUrl } from "../../../utils"
import { Skeleton } from "antd"
import { BaseConsultationImage } from "../../../utils/constant"

interface iProps {
  title?: string
  subTitle?: string
  className?: string
}

const Container = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  min-height: 650px;
  .image-wrapper {
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
`

const FuneralExpenseCardSection: NextPage<iProps> = () => {
  const BannerImage = `${BaseConsultationImage}/static_banner/banner1.png`
  const [bannerImageUrl, setBannerImgUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      if (BannerImage) {
        const imageUrl = await getSignedObjectUrl(BannerImage)
        setBannerImgUrl(imageUrl)
      }
      setIsLoading(false)
    })()
    return () => {
      setBannerImgUrl(null)
    }
  }, [BannerImage])

  return (
    <Container>
      <ConsultationHeaderComponent
        isNoMargin
        navTitle={t("サン・ライフの\nご葬儀費用の考え方")}
        description={
          "ご葬儀費用はA基本費用、Bオプション費用、C宗教者費用で構成されます。サン・ライフではお客様のご要望をお伺いし、必要なプランやサービスをご提案しております。"
        }
      />
      {isLoading ? (
        <Skeleton paragraph={{ rows: 10 }} active />
      ) : (
        <div className="image-wrapper">
          <img src={bannerImageUrl} alt={"banner-1"} loading="lazy" />
        </div>
      )}
    </Container>
  )
}

export { FuneralExpenseCardSection }
