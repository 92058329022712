import { Dispatch, SetStateAction, createContext } from "react"

type ContextProps = {
  loading: boolean
  user: any | null
  authenticated: boolean
  setUser: any
  isAdmin: boolean
  adminRole: string
  setAdminRole: Dispatch<SetStateAction<string>>
  loginLoading: boolean
  setLoginLoading: Dispatch<SetStateAction<boolean>>
  loginId: string
  setLoginId: Dispatch<SetStateAction<string>>
}

export const AuthContext = createContext<Partial<ContextProps>>({})

type AuthProviderProps = {
  loading: boolean
  user: any | null
  setUser: any
  isAdmin: boolean
  children: React.ReactNode
  adminRole: string
  setAdminRole: Dispatch<SetStateAction<string>>
  loginLoading: boolean
  setLoginLoading: Dispatch<SetStateAction<boolean>>
  loginId: string
  setLoginId: Dispatch<SetStateAction<string>>
}

export const AuthProvider = (props: AuthProviderProps) => {
  const {
    loading,
    user,
    setUser,
    children,
    isAdmin,
    loginLoading,
    setLoginLoading,
    adminRole,
    setAdminRole,
    loginId,
    setLoginId,
  } = props
  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated: user !== null,
        setUser,
        isAdmin,
        adminRole,
        setAdminRole,
        loginLoading,
        setLoginLoading,
        setLoginId,
        loginId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
