import { theme } from "@project/shared/src/theme"
import styled from "styled-components"

export const relationOptions = [
  {
    target: "relation",
    label: "家族",
    value: "家族",
  },
  {
    target: "relation",
    label: "親戚",
    value: "親戚",
  },
  {
    target: "relation",
    label: "親友",
    value: "親友",
  },
  {
    target: "relation",
    label: "友人",
    value: "友人",
  },
  {
    target: "relation",
    label: "同僚",
    value: "同僚",
  },
  {
    target: "relation",
    label: "上司",
    value: "上司",
  },
  {
    target: "relation",
    label: "部下",
    value: "部下",
  },
  {
    target: "relation",
    label: "近所",
    value: "近所",
  },
  {
    target: "relation",
    label: "その他",
    value: "その他",
  },
]

export const TextFieldContainer = styled.div`
  margin-bottom: 30px;

  .text-field {
    width: 360px;
  }

  input {
    background-color: ${theme.background.primary};
    border: 1px solid #dedbd5;
    border-radius: 10px;
    box-shadow: none;
  }

  .email_address {
    width: 100%;
  }

  .select-component {
    width: 360px;

    @media (max-width: 786px) {
      width: 100%;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    background: #f8f5ef;
    border: 1px solid #dedbd5;
    border-radius: 10px;

    @media (max-width: 786px) {
      height: 50px;
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    margin-top: 10px;
    font-size: 16px;
  }

  .optional,
  .optionalDown {
    font-weight: 400;
    font-size: 16px;
    color: #d86464;
  }

  .optionalDown {
    margin-top: 16px;
  }

  @media (max-width: 786px) {
    .text-field {
      height: 65px;
      width: 100%;
    }
  }
`

export const LabelContainer = styled.div`
  margin-bottom: 20px;
  @media (max-width: 786px) {
    margin-bottom: 14px;
  }
`

export const Label = styled.div`
  font-weight: 500;
  font-size: 16px;

  ::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(226, 110, 48, 0.2);
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  @media (max-width: 786px) {
    font-size: 16px;

    ::before {
      width: 12px;
      height: 12px;
    }
  }
`

export const ErrorStyled = styled.div`
  font-size: 16px;
  font-family: ${theme.font.NS};
  font-weight: ${theme.text.weight.regular};
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
  display: block;
`

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 35px;

  .confirm-btn {
    width: 200px;
    height: 50px;
    font-weight: 700;
    font-size: 18px;

    @media (max-width: 786px) {
      width: 240px;
      height: 60px;
    }
  }
`
