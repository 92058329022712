import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { getSignedObjectUrl } from "../../../utils"
import { BaseConsultationImage } from "../../../utils/constant"
import { Skeleton } from "antd"
interface ICorresopundingPersonComponent {
  data?: any
}
const Main = styled.div`
  background-color: #ffffff;
  img {
    width: 100%;
    object-fit: contain;
  }
`

const CorresopundingPersonComponent: React.FC<ICorresopundingPersonComponent> =
  ({ data }) => {
    const ManagerImage = `${BaseConsultationImage}/manager/${data?.manager_image}.png`
    const [imgUrl, setImgUrl] = useState(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    useEffect(() => {
      ;(async () => {
        setIsLoading(true)
        if (data?.manager_image) {
          const imageUrl = await getSignedObjectUrl(ManagerImage)
          setImgUrl(imageUrl)
        }
        setIsLoading(false)
      })()
      return () => {
        setImgUrl(null)
      }
    }, [data?.corresponding_person_image])
    if (isLoading) {
      return <Skeleton active />
    }
    if (data?.manager_image) {
      return (
        <Main>
          <a href="tel:0120941447">
            <img src={imgUrl} alt="manager-image" loading="lazy" />
          </a>
        </Main>
      )
    }
    return null
  }

export { CorresopundingPersonComponent }
