import axios from "axios"
import { auth } from "./firebase"
import * as Sentry from "@sentry/node"
import { signOut } from "firebase/auth"
axios.defaults.responseType = "json"

/**
 * axios instance
 */

axios.defaults.responseType = "json"

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Time-Zone": `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
  },
})

API.interceptors.request.use(
  async (config) => {
    if (typeof sessionStorage !== "undefined") {
      if (sessionStorage.getItem("X-Agent-User")) {
        config.headers["X-Agent-User"] = sessionStorage?.getItem("X-Agent-User")
      }
    }
    const token = await auth.currentUser?.getIdToken(true)
    if (token) {
      config.headers["Authorization"] = "Bearer " + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

API.interceptors.response.use(
  (response) => {
    return response?.data
  },
  (error) => {
    if (error?.response?.status === 401) {
      signOut(auth)
    }
    Sentry.captureException(error)
    return Promise.reject(error.response)
  }
)

export { API }
