import { Modal } from "antd"
import styled from "styled-components"

export const TitleWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #e26e30;
  margin-bottom: 8px;
`
export const Content = styled.div`
  margin: 27px 0 0 0;
  .div-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .ant-input {
    font-size: 16px !important;
  }
  .consultation {
    .ant-select-selection-placeholder {
      font-size: 18px;
      color: rgba(49, 49, 49, 0.5);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .memo-field {
    display: flex;
    margin-top: 40px;
    .memo-label {
      width: 140px;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }
  .consult-memo-upload {
    .ant-upload-list-item {
      display: none;
    }
  }
`
export const Required = styled.span`
  color: #e71c1c;
`

export const PasswordInputBox = styled.div`
  display: flex;
  padding-top: 15px;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  .ant-input-affix-wrapper > input.ant-input {
    background-color: #f8f5ef;
  }
  .err-text {
    font-size: 16px;
  }
  .ant-input-password {
    width: 100%;
  }
  .note {
    color: #7c7c7c;
    font-size: 14px;
    font-style: normal;
  }
`

export const FullDiv = styled.div<{ isLastDiv?: boolean; isError?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;

  .input {
    padding-top: 10px;
    margin-top: ${(props) => (props.isError ? "-20px" : null)};
  }

  .error-input {
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .label {
    font-weight: 500;
    font-size: 16px;
    width: 165px;
    min-width: 165px;
    white-space: pre;
  }
  .value {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    white-space: break-spaces;
  }
  .ant-select {
    background-color: #f8f5ef;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #e26e30;
    border-color: #e26e30;
  }
  .show-counting {
    color: #7c7c7c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
export const HalfDiv = styled.div<{ isError?: boolean }>`
  width: calc(50% - 40px);
  height: 65px;
  display: flex;
  padding: 40px 0px;
  align-items: center;
  .label {
    min-width: 165px;
    font-weight: 500;
    font-size: 16px;
    white-space: pre;
  }
  .custom-label {
    margin-top: -10px;
  }
  .input {
    padding-top: 10px;
    margin-top: ${(props) => (props.isError ? "-20px" : null)};
  }

  .error-input {
    padding-top: 10px;
    padding-bottom: 25px;
  }
  .value {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  .select-component {
    background-color: #f8f5ef;
  }
  .ant-picker {
    background-color: #f8f5ef;
    width: 100%;
  }
  .err-text {
    white-space: nowrap;
  }
  .show-counting {
    color: #7c7c7c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .custom-select-width345 {
    .ant-select {
      width: 345px;
    }
  }
`
export const PhoneDiv = styled.div<{ isMarginTop?: boolean }>`
  margin-top: ${(props: any) => (props.isMarginTop ? "16px" : null)};
  width: calc(50% - 40px);
  .phone-wrapper {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    .label {
      min-width: 165px;
      max-width: 165px;
      font-weight: 500;
      font-size: 16px;
    }
    .custom-label {
      margin-top: -10px;
    }
    .input {
      padding-top: 10px;
    }
    .value {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .phone-error-wrapper {
    width: 100%;
    height: 85px;
    display: flex;
    align-items: center;
    .label {
      min-width: 165px;
      font-weight: 500;
      font-size: 16px;
    }
    .custom-label {
      margin-top: -30px;
    }
    .input {
      padding-top: 10px;
      padding-bottom: 25px;
    }
    .value {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .show-counting {
    color: #7c7c7c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
export const MemoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 29px 0 0 0;
`
export const MemoLabelWrapper = styled.div`
  display: flex;
  height: 46px;
  width: 110px;
  flex-direction: column;
  margin: 0 30px 0 0;
  font-weight: 500;
  font-size: 16px;
  white-space: pre;
`

export const ButtonWrapper = styled.div<{ justifyContent?: string }>`
  display: flex;
  justify-content: ${(props) => props?.justifyContent || "flex-end"};
  margin-top: 50px;
  .preview-btn {
    &:active,
    &:hover {
      border: 1px solid #e26e30 !important;
      background-color: #f7f5ef !important;
    }
    &:focus {
      border: 1px solid #e26e30 !important;
    }
  }
`
export const LoaderWrapper = styled.div`
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ModalWrapper = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border: 1px solid #808080 !important;
    border-radius: 10px !important;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
`
export const ModalBodyWrapper = styled.div`
  padding: 50px 33px 25px 33px;
  text-align: center;
  @media (max-width: 576px) {
    padding: 21px;
  }
`
export const ModalTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${(props) => props.theme.font.BUS};
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-size: ${(props) => props.theme.text.size.s};
  .icon {
    margin-right: 11px;
    position: relative;
    top: 2px;
  }
`
export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  @media (max-width: 576px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-top: 27px;
  }
`
