import React from "react"
import styled from "styled-components"
import { ConsultationHeaderComponent } from "../ConsultationHeader"
import { useTranslation } from "react-i18next"
import { ConsultationSubHeaderComponent } from "../ConsultationSubHeader"

interface iProps {
  title?: string
  subTitle?: string
  className?: string
  venue?: string
  hall?: string
}

const Container = styled.div`
  background-color: #e26e3033;
  margin-top: 40px;
  padding-bottom: 30px;

  .sortnumber {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #ffd5c2;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }
`
const ImageContainer = styled.div`
  margin: 0 30px 30px 30px;
  @media (max-width: 576px) {
    margin: 0 20px 20px 20px;
  }
  img {
    width: 100%;
  }
  .desk-img {
    @media (max-width: 576px) {
      display: none;
    }
  }
  .mob-img {
    display: none;
    @media (max-width: 576px) {
      display: block;
    }
  }
`

const FuneralExpenseBreakdownSection: React.FC<iProps> = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <ConsultationHeaderComponent
        navTitle={t("Funeral Basic Expenses")}
        sortingOrder={"A"}
        sortOrderBackground={"#ffd5c2"}
      />
      <ConsultationSubHeaderComponent
        navTitle={t("Family Funeral")}
        background={"rgba(226, 110, 48, 0.20)"}
        counter={1}
      />
      <ImageContainer>
        <img
          className="desk-img"
          src={"/assets/imgs/family-funeral-desk.png"}
        />
        <img className="mob-img" src={"/assets/imgs/family-funeral-mob.png"} />
      </ImageContainer>
      <ConsultationSubHeaderComponent
        navTitle={t("Purposed venue / Ceremony Hall")}
        background={"rgba(226, 110, 48, 0.20)"}
        counter={2}
      />
      <ImageContainer>
        <img
          className="desk-img"
          src={"/assets/imgs/ceremoney-hall-desk.png"}
        />
        <img className="mob-img" src={"/assets/imgs/family-funeral-mob.png"} />
      </ImageContainer>
      {/* <ConsultationSubHeaderComponent
        navTitle={t(
          "Items that may be added depending on the funeral schedule"
        )}
        background={"rgba(226, 110, 48, 0.20)"}
        counter={3}
      />
      <ImageContainer></ImageContainer> */}
    </Container>
  )
}

export { FuneralExpenseBreakdownSection }
