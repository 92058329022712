import { NextPage } from "next"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { ConsultationHeaderComponent } from "../ConsultationHeader"
import {
  BaseConsultationImage,
  OfficiantIntroductionOption,
} from "../../../utils/constant"
import { getSignedObjectUrl } from "../../../utils"
import { Skeleton } from "antd"

interface iProps {
  className?: string
  religion?: string
  denomination?: string
  officiantintroduction?: string
  selectOfficiant?: string
  hint?: string
}

const Container = styled.div`
  background: #e6f2eb;
  * {
    font-family: "BIZ UDGothic", sans-serif;
  }
  .content-section {
    max-width: 1200px;
    margin: auto;
    color: rgba(49, 49, 49, 0.8);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .second-last-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      color: var(--dark_gray, #313131);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      white-space: pre;
      text-align: left;
    }
    .value {
      background: #ccffe1;
      color: var(--dark_gray, #313131);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .first-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    .flex {
      padding: 12px;
      background: #fff;
      border: 1px solid var(--light_gray, #dddcdc);
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      .label {
        color: var(--dark_gray, #313131);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
      }
      .value {
        color: #313131;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        background-color: #ccffe1;
      }

      @media (max-width: 1098px) {
        width: 100%;
        padding: 12px;
      }
    }
    @media (max-width: 1098px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .second-last-row {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    .second-last-flex {
      width: 100%;
      padding: 12px;
      border: 1px solid var(--light_gray, #dddcdc);
      background: #fff;
      height: 70px;
      flex-shrink: 0;
      @media (max-width: 1098px) {
        width: 100%;
        padding: 12px;
      }
    }
    @media (max-width: 1098px) {
      flex-direction: column;
    }
  }
  .last-row {
    display: flex;
    flex-direction: column;
    .second-last-flex {
      width: 100%;
      padding: 12px;
      border: 1px solid var(--light_gray, #dddcdc);
      background: #fff;
      height: 70px;
      flex-shrink: 0;
      @media (max-width: 1098px) {
        width: 100%;
        padding: 12px;
      }
    }
    @media (max-width: 1098px) {
      flex-direction: column;
    }
  }
  .hint {
    text-align: center;
    color: #313131;
    white-space: pre-wrap;
    margin-top: 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  padding-top: 1rem;
`

const ReligiousFeesSection: NextPage<iProps> = ({
  religion,
  denomination,
  officiantintroduction,
  selectOfficiant,
  hint,
}) => {
  const { t } = useTranslation()
  const BannerImage = `${BaseConsultationImage}/denomination/${selectOfficiant}.jpg`
  const [bannerImageUrl, setBannerImgUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      if (
        BannerImage &&
        selectOfficiant !== "表示しない" &&
        selectOfficiant !== "無宗教式"
      ) {
        const imageUrl = await getSignedObjectUrl(BannerImage)
        setBannerImgUrl(imageUrl)
      }
      setIsLoading(false)
    })()
    return () => {
      setBannerImgUrl(null)
    }
  }, [BannerImage])
  if (isLoading) {
    return <Skeleton paragraph={{ rows: 10 }} active />
  }
  return (
    <Container>
      <ConsultationHeaderComponent
        navTitle={t("Religious Fees")}
        sortingOrder={"C"}
        sortOrderBackground={"#ADF8CC"}
        subTitle={t("Options religious Ritual Under Consideration")}
        dotsForDenomination
        description="宗教儀礼を司式していただく宗教者に関連する費用になります。菩提寺がある場合は、直接ご住職にお伺いをたてていただくとよいでしょう。"
      />
      <div className="content-section">
        <div className="first-row">
          <div className="flex">
            <span className="label">{t("Religion")}</span>
            <span className="value">{religion}</span>
          </div>
          <div className="flex">
            <span className="label">{t("Denomination")}</span>
            <span className="value">{denomination}</span>
          </div>
        </div>
        <div className="first-row">
          <div className="flex">
            <span className="label">{t("Introduction of the Officiant")}</span>
            <span className="value">
              {t(OfficiantIntroductionOption[officiantintroduction])}
            </span>
          </div>
        </div>
        {/* <div className="last-item-wrapper">
            <div className="second-last-row">
              <div className="second-last-flex">
                <span className="label">{t("Gift")}</span>
                <span className="value">100,000万円</span>
              </div>
              <div className="second-last-flex">
                <span className="label">{t("Vehicle Charge")}</span>
                <span className="value">100,000万円</span>
              </div>
            </div>
            <div className="last-row">
              <div className="second-last-flex">
                <span className="label">{t("Meal")}</span>
                <span className="value">100,000万円</span>
              </div>
              <div className="second-last-flex">
                <span className="label">
                  {t("Posthumous name\n(Upon Request)")}
                </span>
                <span className="value">100,000万円</span>
              </div>
            </div>
          </div> */}
        {selectOfficiant &&
          !(
            selectOfficiant === "表示しない" || selectOfficiant === "無宗教式"
          ) && (
            <Image
              src={bannerImageUrl}
              alt="select-officiant-image"
              loading="lazy"
            />
          )}
      </div>
      {!!hint && <div className="hint">{`＊${hint}`}</div>}
    </Container>
  )
}

export { ReligiousFeesSection }
