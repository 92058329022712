import React, { useState } from "react"
import styled from "styled-components"
import { NavSection } from "../ViewConsultMemo/NavSection"
import { ConsultationHeaderComponent } from "../ConsultationHeader"
import { BannerCardSection } from "../ViewConsultMemo/BannerCard"
import { FuneralExpenseCardSection } from "../ViewConsultMemo/FuneralExpenseCard"
import { CorresopundingPersonComponent } from "../ViewConsultMemo/CorresopundingPersonComponent"
import { ReligiousFeesSection } from "../ViewConsultMemo/ReligiousFees"
import { RelatedMaterialSection } from "../ViewConsultMemo/RelatedMaterial"
import { SpecialNotesSection } from "../ViewConsultMemo/SpecialNotes"
import { FreeNotesSection } from "../ViewConsultMemo/FreeNotesSection"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { Grid, notification, Divider } from "antd"
import { Button } from "../../atom"
import { theme } from "../../../theme"
import {
  ModalBodyWrapper,
  ModalButtonWrapper,
  ModalTextWrapper,
  ModalWrapper,
} from "../../../utils"
import { updateConsultationMemoAccessibility } from "../../../services"
import { CeremonyHall } from "../ViewConsultMemo/CeremonyHall"
import { FuneralBasic } from "../ViewConsultMemo/FuneralBasic"
import { FinalBannerSection } from "../ViewConsultMemo/FinalBannerSection"
import { LeftOutlined } from "@ant-design/icons"
import { useRouter } from "next/router"

interface IConsultationMemoUI {
  data: Record<string, any>
  isPreview?: boolean
  lockIcon?: any
  unlockIcon?: any
  isUser?: boolean
}

export const FuneralBasicExpenses = styled.div`
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff4f0;
  padding: 20px;
  min-height: 270px;
  @media (max-width: 480px) {
    width: 100%;
  }
  img {
    margin-top: 0.8rem;
    width: 100%;
    object-fit: contain;
  }
  .join-intension-img {
    margin-top: 0 !important;
  }
  a {
    text-decoration: none;
    &:hover {
      :hover {
        color: #000000;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
`

const ContentContainer = styled.div`
  position: relative;
  * {
    font-family: "BIZ UDGothic", sans-serif !important;
  }
  .funeral-wishes {
    width: 375px;
    background: #ffffff;
    padding: 20px;
    padding-bottom: 20px;
    @media (max-width: 768px) {
      background: #ffffff;
      padding: 20px;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .expense-card {
    width: 375px;
    background: #f7f8f9;
    padding: 20px;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .btn-comp {
    width: 375px;
    background: #f7f8f9;

    .ant-divider-horizontal {
      margin: 0 0 20px 0;
    }
    img {
      width: 100%;
      object-fit: contain;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .banner-card {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (max-width: 1006px) {
      flex-direction: column;
    }
  }
  .denomination-section {
    width: 375px;
    background: #e6f2eb;
    padding: 20px 20px 20px 20px;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .corresponding-person-section {
    width: 375px;
    background: #fff;
    padding: 20px;
    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .flex-center {
    position: relative;
    display: flex;
    justify-content: center;
  }
`

export const OptionFees = styled(FuneralBasicExpenses)`
  background-color: #f4ecfb;
  position: relative;
  min-height: 235px;
  .grid-img {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 16px;
    width: 100%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .static-info {
    margin-top: 30px;
  }
  .slick-dots-top {
    top: -45px !important;
  }
`
export const HintComp = styled(FuneralBasicExpenses)`
  background-color: #fff;
  width: 375px;
  padding: 20px;
  min-height: 0;
  @media (max-width: 480px) {
    width: 100%;
  }
  .hint {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    color: #313131cc;
    white-space: pre-wrap;
  }
`

export const BannerImage = styled.div`
  margin: 20px 0 6px 0;
  .title {
    background: #f9d9c9;
    display: flex;
    padding: 4px 0px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
  }
  .dummy-img {
    width: 100%;
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 120px;
  left: calc(50% - 300px);
  .back-btn {
    .anticon svg {
      height: 15px;
    }
    @media (max-width: 786px) {
      display: none;
    }
  }
`
const MainWrapper = styled.div`
  background: #e2e2e2;
  .special-notes-section {
    width: 375px;
    background: #f7f8f9;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .free-notes-section {
    width: 375px;
    background: #fff;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .related-materials-section {
    width: 375px;
    background: #fffded;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .family-share-section {
    width: 375px;
    background: #fff;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .family-share-btn {
    width: 240px;
    height: 60px;
    border-radius: 100px;
    border: 1px solid #e26e30;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #e26e30;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
  }
  .flex-center {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 785px) {
    margin-bottom: -45px;
  }
`

const LockWrapper = styled.span`
  .home__icon path,
  .home__icon_unlock path {
    stroke: #e26e30;
    transform: scale(1.1);
  }
`

const ConsultationMemo: React.FC<IConsultationMemoUI> = ({
  data,
  isPreview,
  lockIcon: LockIcon,
  unlockIcon: UnlockIcon,
  isUser,
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const [modalState, setModalState] = useState(false)
  const [isAccessible, setIsAccessible] = useState(data?.is_accessible)

  const { useBreakpoint } = Grid
  const breakPoint = useBreakpoint()

  const handleLockClick = (id: string) => {
    mutate(id)
    setIsAccessible((prev) => {
      if (prev === 0) {
        return 1
      }
      return 0
    })
  }
  //API for lock feature
  const { mutate } = useMutation(updateConsultationMemoAccessibility, {
    onSuccess: () => {
      setModalState(false)
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("An error has occurred. Please try again later."),
      })
      setIsAccessible((prev) => {
        if (prev === 0) {
          return 1
        }
        return 0
      })
    },
  })

  return (
    <MainWrapper>
      <ModalWrapper
        closable={false}
        visible={modalState}
        width={breakPoint.xs ? "350px" : "729px"}
        centered
        footer={null}
      >
        <ModalBodyWrapper>
          <ModalTextWrapper>
            {isAccessible ? (
              <LockWrapper>
                <LockIcon className="home__icon" height={30} width={30} />
              </LockWrapper>
            ) : (
              <LockWrapper>
                <UnlockIcon
                  className="home__icon_unlock"
                  height={30}
                  width={30}
                />
              </LockWrapper>
            )}
            {t(
              "You can make the information public or private to your viewers by clicking the lock icon."
            )}
          </ModalTextWrapper>
          <ModalButtonWrapper>
            <Button
              width={breakPoint.xs ? "240px" : "160px"}
              height="50px"
              onClick={() => setModalState(false)}
              background={theme.button.secondary}
            >
              {t("Cancel")}
            </Button>
            <Button
              width={breakPoint.xs ? "240px" : "160px"}
              height="50px"
              margin={breakPoint.xs ? "0px 0 10px 0" : "0 0 0 22px"}
              background={theme.button.primary}
              onClick={() => handleLockClick(data?.id)}
            >
              {isAccessible ? t("Public") : t("Lock")}
            </Button>
          </ModalButtonWrapper>
        </ModalBodyWrapper>
      </ModalWrapper>
      <div className="flex-center">
        <NavSection
          date={data?.consultation_date}
          consultTitle={data?.consultation_title}
          consultation={data?.user_name}
          corresponding={data?.corresponding_person}
        />
        {isUser && (
          <Wrapper>
            <Button
              background={theme.table.headerBackground}
              color={theme.text.color.dark}
              padding="0px"
              width="80px"
              height="36px"
              borderradius="10px"
              className="back-btn"
              onClick={() => router.back()}
            >
              <LeftOutlined />
              {t("Back")}
            </Button>
          </Wrapper>
        )}
      </div>
      <ContentContainer>
        <div className="flex-center">
          <div className="funeral-wishes">
            <ConsultationHeaderComponent
              navTitle={
                "[ Funeral wishes/ Situation ]\n* Participants Predictions"
              }
            />
            <div className="banner-card">
              <BannerCardSection
                title="Relatives"
                subTitle={data?.funeral_relatives || 0}
                image={<img src="/assets/icons/consultbanner2.png" alt="img" />}
              />
              <BannerCardSection
                title="Mourner"
                subTitle={data?.funeral_mourners || 0}
                image={<img src="/assets/icons/consultbanner1.png" alt="img" />}
              />
            </div>
          </div>
        </div>
        <div className="flex-center">
          <div className="expense-card">
            <FuneralExpenseCardSection />
            {/* <FuneralExpenseBreakdownSection
              venue={`${data?.proposed_venue} / ${data?.ceremony_hall}`}
            />
            <OptionFeeSection /> */}
          </div>
        </div>
        <div className="flex-center">
          <div className="btn-comp">
            <Divider />
            <img src={"/assets/imgs/img-btn.png"} />
          </div>
        </div>
        {/***
         * start Funeral basic **/}
        <FuneralBasic data={data} />
        {/***
         * End Funeral basic
         */}
        {!!data?.proposed_venue || !!data?.desired_option?.length ? (
          <div className="flex-center">
            <CeremonyHall data={data} />
          </div>
        ) : null}
        <div className="flex-center">
          <div className="denomination-section">
            <ReligiousFeesSection
              religion={data?.religion}
              denomination={data?.denomination}
              officiantintroduction={data?.officiant_introduction}
              selectOfficiant={data?.select_officiant}
              hint={data?.hint}
            />
          </div>
        </div>
        {data?.manager_image && (
          <div className="flex-center">
            <div className="corresponding-person-section">
              <CorresopundingPersonComponent data={data} />
            </div>
          </div>
        )}
      </ContentContainer>
      <div className="flex-center">
        <div className="related-materials-section">
          <RelatedMaterialSection fileData={data?.material} />
        </div>
      </div>
      {/* <RelatedMaterialImageContainer>
          <img
            className="img-class"
            src={"/assets/imgs/related-material.png"}
          />
        </RelatedMaterialImageContainer> */}
      {/* Special Notes / Individual proposals
        -->>>this is second last screen */}
      <div className="flex-center">
        <FinalBannerSection data={data} />
      </div>
      {data?.notice && (
        <div className="flex-center">
          <div className="special-notes-section">
            <SpecialNotesSection notes={data?.notice} />
          </div>
        </div>
      )}
      <div className="flex-center">
        <div className="free-notes-section">
          <FreeNotesSection
            id={data?.id}
            freeNotes={data?.free_notes}
            data={data || []}
            isOnlyPreview={isPreview}
          />
        </div>
      </div>
      <div className="flex-center">
        {!isPreview && (
          <div className="family-share-section">
            <div
              className="family-share-btn"
              onClick={() => {
                setModalState(true)
              }}
            >
              {isAccessible ? (
                <LockWrapper style={{ marginTop: "8px" }}>
                  <LockIcon className="home__icon" height={30} width={30} />
                </LockWrapper>
              ) : (
                <LockWrapper style={{ marginTop: "6px" }}>
                  <UnlockIcon
                    className="home__icon_unlock"
                    height={30}
                    width={30}
                  />
                </LockWrapper>
              )}
              <span>家族にシェア</span>
            </div>
          </div>
        )}
      </div>
    </MainWrapper>
  )
}

export { ConsultationMemo }
